<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<div v-else class="container-fluid">
		<div class="page-header">
			<CRow>
				<CCol md="12">
					<CRow>
						<CCol md="8">
							<h2 class="typo-h4 d-flex align-items-center">{{ edit.data.title }}</h2>
						</CCol>
						<CCol class="d-flex justify-content-end mt-2">
							<CBadge
								:color="edit.data.status.color"
								class="badge-status"
								data-test-id="badge-order-status"
							>
								{{ edit.data.status.title }}
							</CBadge>
						</CCol>
					</CRow>
				</CCol>
			</CRow>
			<CRow class="summary">
				<CCol md="12">
					<p class="typo-body-2 m-0 p-0">
						Last updated by: <span class="color-hypertext">{{ edit.data.updatedBy }}</span><span class="date color-black-45">{{ edit.data.updatedAt }}</span>
					</p>
					<p class="typo-body-2 m-0">
						Created by: <span class="color-hypertext">{{ edit.data.createdBy }}</span><span class="date color-black-45">{{ edit.data.createdAt }}</span>
					</p>
				</CCol>
			</CRow>
		</div>
		<FormMobileNotification
			:is-submitting="edit.isUpdating"
			:form="defaultData"
			is-edit
			@onConfirm="handleUpdate"
		/>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import FormMobileNotification from '@/components/FormMobileNotification.vue';

export default {
	name: 'MobileNotificationEdit',
	components: {
		FormMobileNotification,
	},
	data() {
		return {
			isLoading: true,
			defaultData: null,
		};
	},
	computed: {
		...mapState('mobileNotification', {
			edit: 'edit',
		}),
	},
	async created() {
		const id = this.$route?.params?.id ? Number(this.$route.params.id) : null;
		if (id) {
			try {
				this.isLoading = true;
				await this.getNotification(id);
				this.extractData();
			} finally {
				this.isLoading = false;
			}
		}
	},
	methods: {
		...mapActions({
			UpdateNotification: 'mobileNotification/UpdateNotification',
			getNotification: 'mobileNotification/getNotification',
		}),
		async handleUpdate(formData) {
			await this.UpdateNotification(formData);
		},
		extractData() {
			if (this?.edit?.data) {
				this.defaultData = this.edit.data;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.page-header {
		margin: 0 rem(-28);
		padding: 0 rem(28);

		.typo-h4 {
			word-break: break-all;
		}
	}

	.summary {
		.date {
			&::before {
				content: "|";
				display: inline-block;
				margin-left: rem(5);
				margin-right: rem(5);
			}
		}
	}

	.badge-status {
		width: auto;
		padding: rem(7) rem(8);
		margin-left: rem(16);
		font-size: rem(14);
		height: rem(28);
	}
</style>
